.container {
  width: 36px;
  height: 36px;
  font-size: var(--font-size-md);
  border-radius: 999px;
  background-color: var(--color-bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
}