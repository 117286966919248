.primary-nav,
.secondary-nav {
    padding: 0;
    width: 100%;
}

.primary-nav > li > a,
.secondary-nav > li > button {
    overflow: hidden;
    white-space: nowrap;
}

.primary-nav li,
.secondary-nav li {
    list-style: none;
    position: relative;
}

.primary-nav li a,
.secondary-nav li button {
    padding: 1rem 1.5rem;
    display: block;
    text-decoration: none;
    transition: 0.15s;
    width: 100%;
    color: var(--color-text-menu-lt);
}

.primary-nav > li a svg,
.secondary-nav > li button svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
    color: var(--color-text-menu);
}

.primary-nav > li a:hover,
.secondary-nav li button:hover {
    color: #fff;
}

.primary-nav > li a svg:first-child,
.secondary-nav > li button svg:first-child {
    margin-right: 0.5rem;
}

.primary-nav-toggler {
    font-size: 24px;
    background: none;
    border: none;
    color: var(--color-text-menu);
    transition: 0.2s;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.primary-nav-toggler.hovered {
    color: #fff;
    background-color: var(--color-bg-menu-lt);
}

.primary-nav-collapsed {
    position: absolute;
    top: 0;
    left: 100%;
    padding: 0;
    width: 250px;
    background-color: var(--color-bg-menu);
    z-index: 999;
    display: none;
}

.primary-nav-collapsed li:first-child a {
    height: 56px;
    display: flex;
    align-items: center;
    background-color: var(--color-bg-menu-lt);
    padding: 1rem;
    color: #fff;
}

.primary-nav-collapsed li:nth-child(2) a {
    padding-top: 1rem;
}

.primary-nav-collapsed li:last-child a {
    padding-bottom: 1rem;
}

.primary-nav-collapsed li a {
    padding: 0.5rem 2rem;
    color: var(--color-text-menu);
}

.primary-nav-collapsed.hovered {
    display: block;
}

.secondary-nav button {
    text-align: left;
    background: none;
    border: 0;
}

.secondary-nav li a {
    padding-left: 3.3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;
    color: var(--color-text-menu);
}

.arrow {
    position: absolute;
    right: 1rem;
    transition: 0.2s;
}

.arrow.active {
    transform: rotate(-180deg);
}

.submenu-links {
    overflow: hidden;
    max-height: 0;
    transition: 0.3s ease-in-out;
}

.submenu-links.active {
    display: block;
    max-height: 50vh;
}

.nav-link.active {
    color: #fff !important;
}

.nav-link.active svg {
    color: #fff !important;
}

.logo {
    filter: brightness(0) invert(1);
    width: 100%;
    max-width: 100px;
    padding: 0.25rem;
}

.active {
    color: #fff;
}

.container {
    background-color: var(--color-bg-menu);
    width: 70px;
    min-width: 70px;
    transition: 0.2s;
}

.container.collapsed {
    width: 280px;
    min-width: 280px;
}

.tour-link {
    color: #939dbd;
    margin-left: 23px;
    margin-top: 13px;

    display: flex;
    align-items: center;
}
.tour-link:hover {
    text-decoration: none;

    color: #fff;
}
