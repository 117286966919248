.content {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;

  z-index: 2;

  overflow: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;

  z-index: 1;
}

.close {
  font-size: 24px;
  font-weight: bold;
  background: none;
  border: 0;
  transition: 0.2s;
}

.close:hover {
  opacity: 0.8;
}

@keyframes slideIn{
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@keyframes fadeIn{
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}