:root {
  --color-bg-lt: #F8F8FB;
  --color-bg-primary: #D4DBF9;
  --color-bg-menu: #062f50;
  --color-bg-menu-lt: #0b5189;
  --color-primary: #0B5189;
  --color-primary-dk: #3956e1;
  --color-text: #495057;
  --color-text-menu: #79829c;
  --color-text-menu-lt: #a6b0cf;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.25rem;
  --font-size-xl: 1.625rem;
}

html, body, #root {
  height: 100%;
  font-size: 100%;
  color: var(--color-text);
}

#root {
  display: flex;
  flex-direction: column;
}

.error-message {
  color: var(--danger);
  font-size: var(--font-size-sm) !important ;
}

.form-field {
  font-size: var(--font-size-sm);
  padding: 0.5rem 0.75rem;
}

* {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.react-tabs__tab-list {
  display: flex;
  margin-bottom: 0;
}

.react-tabs__tab-list.sub-tab li {
  font-weight: 400;
  font-size: 14px;
}

.react-tabs__tab-list li {
  flex: 1;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #D9D9DC;
  padding: 0.75rem 1rem;
  border-top: none !important ;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tabs__tab-list li:hover {
  border-top: 1px solid #e9ecef;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-tabs__tab--selected {
  border-bottom: 2px solid var(--color-primary) !important;
  border-top: 1px solid rgba(0,0,0,0);
  border-left: 1px solid rgba(0,0,0,0);
  border-right: 1px solid rgba(0,0,0,0);
  color: var(--color-primary) !important;
  background-color: inherit;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  /* font-weight: bold !important; */
}

.react-tabs__tab--selected::after {
  display: none;
}

.react-tabs__tab--selected:focus {
  border-top: 1px solid #e9ecef;
  border-left: 1px solid #e9ecef;
  border-right: 1px solid #e9ecef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: none;
}

.nav--tab a {
  font-weight: bold;
  color: var(--color-text) !important;
  background-color: #;
}

.nav--tab-active a {
  border-bottom: 2px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  background-color: unset !important;
}

.nav--tab-active a:hover {
  border-bottom: 2px solid var(--color-primary) !important;
}

.subnav--tab a {
  font-size: 14px !important;
}

.select_input {
  background-color: white;
  border: 1px solid lightgray;
  font-size: var(--font-size-sm);
  padding: 0.5rem 0.75rem;
}

.card__header--home {
  min-height: 36px;
  display: flex;
  align-items: center;
}

.input-font--sm input {
  font-size: var(--font-size-sm);
}