.groups-list {
  max-width: 220px;
  min-width: 220px;
}

.groups-list .group-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.active-group {
  background-color: #dae0e5!important;
}