.dropdown a {
  display: flex;
  align-items: center;
  color: var(--color-text) !important;
}

.menuToggler {
  font-size: var(--font-size-lg);
  cursor: pointer;
}

.container {
  background-color: var(--color-bg-lt) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);;
}