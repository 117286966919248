body {
    font: 400 14px Roboto, sans-serif;
}

input, button, textarea {
    font: 400 18px Roboto, sans-serif;
}

form div input{
    height: 40px;
    color: #333;
    border: 1px solid #333;
}

button:hover {
    filter: brightness(90%);
}

.authLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;
    text-decoration: none !important;
    font-weight: 300;
    color: #0B5189;
    transition: opacity 0.2s;
}

.authLink:hover {
    opacity: 0.8;
}

.authContainer {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.authContainer .authImg {
    width: 40%;
}

.authContainer section.form{
    display: flex;
    margin-left: 15%;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-width: 350px;
    margin-right: 30px;
}


.authContainer section.form form {
    width: 100%;
    margin-top: 40px;
}

.authContainer section.form form h4 {
    color: #0B5189;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.forgotLink {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 15px;
    margin-bottom: 5px;
    text-decoration: none !important;
    font-weight: 300;
    color: #0B5189;
    transition: opacity 0.2;
}

.forgotLink:hover{
    opacity: 0.8;
}