.title{
    color: gray;
    font-size: 1.5rem;
}

.input_label{
    color: #435465;
    font-size: 1rem;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

.input_border{
    border: 1px solid lightgray;
}

.input_error{
    border-left: 5px solid red;
}

.select_input{
    background-color: white;
    font-size: 1.2rem;
}

.error_message{
    color: red;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
}

.button_add_item{
    background-color: transparent;
    border: 1px solid gray;
}

.button_add_item:hover{
    background-color: lightseagreen;
    border: 1px solid white;
}

.button_submit_form{
    background-color: lightcoral;
    border: 1px solid red;
}

.button_submit_form:hover{
    background-color: lightcoral;
    border: 1px solid white;
}