.left_table {
    width: 150px;
    height: 40px;
    margin-top: 180px;
    transform: rotate(-90deg);
    /* margin-left: -140px; */
}

.external {
    margin-top: 125px;
}

.gridContainer {
    /* display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". ."
        ". ."
        ". ."; */
    display: grid;
    grid-template-columns: 0.2fr 1.1fr 1.4fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". . Fraqueza Fraqueza"
        "a . Ataque Ataque"
        "a . manutencao manutencao";
}
.a {
    grid-area: a;
}
.Fraqueza {
    grid-area: Fraqueza;
}
.Ataque {
    grid-area: Ataque;
}
.manutencao {
    grid-area: manutencao;
}

.identification_column {
    margin-right: 0rem;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.identification_column div {
    width: 100%;
}

.identification_column div span {
    font-weight: 400;
    writing-mode: vertical-lr;
}

.gridItem {
    background-color: rgba(255, 255, 255, 0.8);
    font-size: 30px;
    text-align: center;
}
