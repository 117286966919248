.title {
    color: gray;
    font-size: 1.5rem;
}

.input_label {
    color: var(--color-text);
    font-size: 1rem;
    font-weight: 500;
}

.input_border {
    border: 1px solid lightgray;
}

.input_error {
    border-left: 5px solid var(--danger);
}

.select_input {
    background-color: white;
    font-size: 1.2rem;
}

.error_message {
    color: var(--danger);
    font-size: 0.9rem;
    font-weight: 500;
}

.button_add_item {
    background-color: transparent;
    border: 1px solid gray;
}

.button_add_item:hover {
    background-color: lightseagreen;
    border: 1px solid white;
}

.button_submit_form {
    background-color: lightcoral;
    border: 1px solid red;
}

.button_submit_form:hover {
    background-color: lightcoral;
    border: 1px solid white;
}

.input_border:disabled {
    background-color: white;
    color: blue;
    opacity: 0.5;
}

.left_table {
    height: 38px;
    margin-bottom: 8px;
    width: 150px;
}
