.btn {
  font-size: var(--font-size-sm) !important;
  padding: 0.5rem 0.75rem !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.primary {
  background-color: var(--color-primary) !important;
}

.primary:hover {
  background-color: var(--color-primary-dk) !important;
}

.link, .link:hover, .link:active, .link:focus {
  background: none !important;
  border: none !important;
  color: var(--color-primary) !important;
}

.link:hover {
  color: var(--color-primary-dk) !important;
}