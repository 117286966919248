.identification_column {
    margin-right: 0rem;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.identification_column div {
    width: 100%;
}

.identification_column div span {
    font-weight: 400;
    writing-mode: vertical-lr;
}

.swot_square {
    border: 1px solid white;
    border-radius: 5px 0 0 0;
    height: 100%;
}

.swot_square .title {
    font-weight: 700;
    color: white;
    font-size: 1.3rem;
}

.swot_square .list {
    padding: 10px;
}

.swot_square .list ul {
    list-style: none;
    padding: 0px;
}

.swot_square .list ul li {
    color: white;
    border: 1px solid white;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 5px;
    width: 100%;
}

.swot_square .list ul li:hover {
    background-color: white;
    color: black;
}

.gridContainer {
    display: grid;
    grid-template-columns: 0.2fr 1.1fr 1.4fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". . fraqueza fraqueza"
        "a . ataque ataque"
        "a . manutencao manutencao";
}
.a {
    grid-area: a;
}
.fraqueza {
    grid-area: fraqueza;
}
.ataque {
    grid-area: ataque;
}
.manutencao {
    grid-area: manutencao;
}
